var abp = abp || {};
(function () {
    /* Application paths *****************************************/

    abp.multiTenancy.setTenantIdCookie = function (tenantId) {
        if (tenantId) {
            abp.utils.setCookieValue(
                abp.multiTenancy.tenantIdCookieName,
                tenantId.toString(),
                new Date(new Date().getTime() + 5 * 365 * 86400000), //5 years
                abp.appPath,
                abp.domain,
                {secure: location.protocol === 'https:'}
            );
        } else {
            abp.utils.deleteCookie(abp.multiTenancy.tenantIdCookieName, abp.appPath);
        }
    };

    abp.auth.setToken = function (authToken, expireDate) {
        abp.utils.setCookieValue(abp.auth.tokenCookieName, authToken, expireDate, abp.appPath, abp.domain, {secure: location.protocol === 'https:'});
    };
})();
